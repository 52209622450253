const DEV = process.env.NODE_ENV === 'development';
import Rellax from 'rellax';
  // core version + navigation, pagination modules:
  import Swiper, { Navigation, Pagination, Grid } from 'swiper';
  // import Swiper and modules styles
  import 'swiper/css';
  // import 'swiper/css/navigation';
  import 'swiper/css/pagination';

  // configure Swiper to use modules
  Swiper.use([Navigation, Pagination]);


export default {
  init() {
    // JavaScript to be fired on all pages
    console.log('init common');

    // Remove no-js
    document.documentElement.className = "js";

    // Test touch
    if (!("ontouchstart" in document.documentElement)) {
      document.documentElement.classList.add("no-touch");
    } else {
      document.documentElement.classList.add("touch");
    }

    const menu_btn = document.querySelectorAll('.menu_btn')[0]
    const menu_full = document.querySelectorAll('.menu-full')[0]
    const menu_item = document.querySelectorAll('.menu-item')

    menu_item.forEach((item, i) => {
      item.addEventListener('click', function(e) {
        menu_full.classList.remove('show_menu');
        document.body.classList.remove('noScroll');
        menu_btn.classList.remove('active');
      });
    });


    menu_btn.addEventListener('click', function(e) {
      this.classList.toggle('active');
      menu_full.classList.toggle('show_menu');
      document.body.classList.toggle('noScroll');
    });

    document.addEventListener('keyup', function(e) {
      if ( e.keyCode === 27 && $('.menu-full').hasClass('show_menu')){
        menu_full.classList.remove('show_menu');
        document.body.classList.remove('noScroll');
        menu_btn.classList.remove('active');
      }
    });


    const loader = document.getElementById('loader')

    function hideLoader(){
      loader.classList.add('hide');
    }

    function showLoader(){
      loader.classList.add('show');
    }


  /*--
      PARALLAX
  -----------------------------------*/
    var rellax = new Rellax('.rellax');
    

  /*--
      SLIDER "Testimonianze"
  -----------------------------------*/
    const swiper_testimonianze = new Swiper('.swiper-testimonianze', {
      loop: true,
      pagination: {
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });



  
  /*--
      SLIDERS "gallery"
  -----------------------------------*/

  var elementList = document.querySelectorAll('.gallery-slider');
  Array.prototype.forEach.call(elementList, function (e) {
    const swiper_testimonianze = new Swiper(e, {
      loop: true,
      // autoHeight: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  });
  







  /*--
      SLIDER "Collina è"
  -----------------------------------*/
  const swiper_collina = new Swiper('.swiper-collina-e', {
    loop: true,
    pagination: {
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

    /*--
      SLIDER "Sostienici"
  -----------------------------------*/

  if ( window.matchMedia( '(min-width:1024px)' ).matches === true ) {
  //   console.log("aaa");
    const swiper_sostienici = new Swiper('.swiper-sostieni', {
      loop: false,
      slidesPerView: 3.25,
      spaceBetween: 10,
      breakpoints: {
        1440: {
          slidesPerView: 4,
        }
      }
  
    });

  }




  /*--
      VIDEO LIGHTBOX
  -----------------------------------*/

  var lbx_launch = document.getElementsByClassName("js-lbx-launch");
  var player;
  if(lbx_launch.length){
    var video_iframe = document.getElementById("lightbox-video");
    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    document.getElementById('js-lbx-close').addEventListener("click", function(e) {
      e.preventDefault();
      player.stopVideo();
      document.body.classList.remove("lbx-open");
      //video_iframe.setAttribute('src','' );
    });    
    for (var i = 0; i < lbx_launch.length; i++) {
      lbx_launch[i].addEventListener("click", showVideo, false);
    }
  }
  window.onYouTubeIframeAPIReady = function() {
    video_iframe.setAttribute('src', video_iframe.getAttribute('data-src') );
    player = new YT.Player('lightbox-video', {
      events: {
        //'onReady': onPlayerReady,
        //'onStateChange': onPlayerStateChange
      }
    });
  }
  function showVideo(e) {
    e.preventDefault();
    document.body.classList.add("lbx-open");
    player.playVideo();
  }

  /* ----------------------------------- */




    /*--
     FAQ ACCORDION
  -----------------------------------*/

  function initAcc(elem, option){
    //addEventListener on mouse click
    document.addEventListener('click', function (e) {
        //check is the right element clicked
        console.log(e);
        if (!e.target.matches(elem+' .a-btn')) return;
        else{
            //check if element contains active class
            if(!e.target.parentElement.classList.contains('active')){
                if(option==true){
                     //if option true remove active class from all other accordions 
                    var elementList = document.querySelectorAll(elem +' .a-container');
                    Array.prototype.forEach.call(elementList, function (e) {
                        e.classList.remove('active');
                    });
                }    
                //add active class on cliked accordion     
                e.target.parentElement.classList.add('active');
            }else{
                //remove active class on cliked accordion     
                e.target.parentElement.classList.remove('active');
            }
        }
    });
}
 
//activate accordion function
initAcc('.accordion', true);







    if( !DEV ) {
      showLoader()
      setTimeout(function(){hideLoader()}, 2000);

      window.addEventListener('load', function(){
        hideLoader();
      });
    }

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    console.log('finalize common');
  },
};
